<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<component
		:is="getContentBlockType.component"
		v-else
		is-edit-mode
		:is-loading="isLoading"
		:is-updating="isUpdating"
		:data="data"
		:content-block-type="getContentBlockWidget"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import ContentBlockHighlightForm from '@/components/ContentBlockHighlightForm.vue';
import ContentBlockStripeForm from '@/components/ContentBlockStripeForm.vue';
import ContentBlockTextWithImageForm from '@/components/ContentBlockTextWithImageForm.vue';

import { CONTENT_BLOCK_TYPE, CONTENT_BLOCK_WIDGET_TYPE, CONTENT_BLOCK_FORM_COMPONENTS } from '../enums/contentBlocks';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'ContentBlockEdit',

	components: {
		ContentBlockHighlightForm,
		ContentBlockStripeForm,
		ContentBlockTextWithImageForm,
	},

	props: {
		contentBlockType: {
			type: String,
			default: CONTENT_BLOCK_TYPE.HIGHLIGHT,
		},
	},

	data() {
		return {
			CONTENT_BLOCK_FORM_COMPONENTS,
			id: Number(this.$route.params.id) || null,
		};
	},

	computed: {
		...mapState('contentBlocks', {
			edit: 'edit',
		}),

		...mapGetters({
			getContentBlockDetail: 'contentBlocks/getContentBlockDetail',
		}),

		getContentBlockType() {
			return CONTENT_BLOCK_FORM_COMPONENTS[this.contentBlockType];
		},

		getContentBlockWidget() {
			return CONTENT_BLOCK_WIDGET_TYPE[this.contentBlockType];
		},

		isLoading() {
			return this.edit.isLoading;
		},

		isUpdating() {
			return this.edit.isUpdating;
		},

		data() {
			return this.getContentBlockDetail;
		},
	},

	watch: {
		data(value) {
			this.setPageTitle({
				title: value.name,
				isShow: true,
			});
		},
	},

	async created() {
		await this.getContentBlock({ id: this.id, type: CONTENT_BLOCK_TYPE[this.contentBlockType] });

		this.setPageTitle({
			title: this.data.name,
			isShow: true,
		});

		this.addBreadcrumb({
			title: CONTENT_BLOCK_FORM_COMPONENTS[this.contentBlockType.toUpperCase()].title,
			route: {
				name: CONTENT_BLOCK_FORM_COMPONENTS[this.contentBlockType.toUpperCase()].routerTo,
			},
		});
	},

	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			addBreadcrumb: 'breadcrumb/addBreadcrumb',
			showToast: 'toast/showToast',

			getContentBlock: 'contentBlocks/getContentBlock',
			updateContentBlock: 'contentBlocks/updateContentBlock',
		}),

		async handleSubmit(updatedData) {
			try {
				await this.updateContentBlock({ id: this.id, params: updatedData });
			} finally {
				scrollToTop();
			}
		},
	},
};
</script>
